type UserDiet = {
  id: string
  typePlan: string
  fecha: string
}

function getLastDietPlan(): UserDiet | null {
  const data = localStorage.getItem("pon_user_info_diet")

  if (!data || data === "null") return null

  try {
    const parsed: UserDiet[] = JSON.parse(data)

    if (!Array.isArray(parsed)) return null

    return parsed[parsed.length - 1]
  } catch (error) {
    console.error("Error getting last diet plan from ls:", error)
    return null
  }
}

function getIsGeopolPlan(plan: UserDiet): boolean {
  return plan.typePlan === "geopol"
}

export type { UserDiet }
export { getLastDietPlan, getIsGeopolPlan }
