import React, { useState, useEffect } from "react"
import "./YourDiet.css"

import Image from "../../../../../assets/img/salad-big.jpg"
import ImageGift from "../../../../../assets/img/gift-image.jpg"
import Icon from "../../../../../assets/img/download-big.png"
import Info from "../../../../../assets/img/info_icon.png"
import {
  getDietPlanService,
  verifyVideocallDietService
} from "../../../../../services/getDietPlanService"
import PrivateLoading from "../../../../shared/loading/PrivateLoading"

const downloadInNewTabDiet = (downloadUrl: string) => {
  const link = document.createElement("a")
  link.href = downloadUrl
  link.target = "_blank"
  link.download = "your_diet.pdf"
  link.click()
  window.open(downloadUrl, "_blank")
}

const downloadInNewTabCalendar = (downloadUrl: string) => {
  const link = document.createElement("a")
  link.href = downloadUrl
  link.target = "_blank"
  link.download = "your_interactive_calendar.pdf"
  link.click()
  window.open(downloadUrl, "_blank")
}

const YourDiet: React.FC<{
  isGeopolPlan: boolean
  handleOptionClick: (option: string) => void
}> = ({ isGeopolPlan, handleOptionClick }) => {
  const [loading, setLoading] = useState(false)
  const [citaPendiente, setCitaPendiente] = useState(false)
  const [citaRealizada, setCitaRealizada] = useState(false)
  const [lastError, setLastError] = useState<string | null>(null)
  const [dietPlan, setDietPlan] = useState<Blob | null>(null)
  const [interactiveCalendar, setinteractiveCalendar] = useState<Blob | null>()

  const onClick = ($tipoPdf: String) => {
    if ($tipoPdf === "diet") {
      if (dietPlan !== undefined && dietPlan !== null) {
        const downloadUrl = URL.createObjectURL(dietPlan)
        //window.open(downloadUrl, '_blank');
        downloadInNewTabDiet(downloadUrl)
      }
    }
    if ($tipoPdf === "calendar") {
      if (interactiveCalendar !== undefined && interactiveCalendar !== null) {
        const downloadUrl = URL.createObjectURL(interactiveCalendar)
        downloadInNewTabCalendar(downloadUrl)
      }
    }
  }
  useEffect(() => {
    async function fetchDocumentName() {
      try {
        const documentName = await getDietPlanService()
        if (documentName.length < 2 && documentName.length > 0) {
          setDietPlan(documentName[0])
          setLoading(true)
          setLastError(null)
        }
        if (documentName.length === 2) {
          setDietPlan(documentName[0])
          setinteractiveCalendar(documentName[1])
          setLoading(true)
          setLastError(null)
        }
        if (documentName.length === 0) {
          const cites = await verifyVideocallDietService()
          setCitaPendiente(cites.data.citaPendiente)
          setCitaRealizada(cites.data.citaRealizada)
          setLoading(true)
          setLastError(null)
        }
      } catch (e) {
        setLoading(true)
        setLastError((e as Error).message)
      }
    }
    fetchDocumentName()
  }, [])

  const pon_user_info_diet = localStorage.getItem("pon_user_info_diet")
  let typePlan: any = ""

  if (pon_user_info_diet !== null) {
    const userInfoArray = JSON.parse(pon_user_info_diet)
    if (userInfoArray.length > 0) {
      const lastUserInfo = userInfoArray[userInfoArray.length - 1]
      typePlan = lastUserInfo.typePlan
    } else {
      typePlan = userInfoArray[0].typePlan
    }
    console.log(typePlan)
  } else {
    console.log("No user info found in localStorage.")
  }

  return (
    <>
      <div className="your_diet_container">
        <h1> {isGeopolPlan ? "TU GUÍA NUTRICIONAL" : "TU DIETA"} </h1>
        {!loading && <PrivateLoading />}

        {loading && lastError !== null && (
          <>
            <p>
              Descarga desde aquí tu menú de dieta personalizado. Podrás visualizarlo en tu
              navegador o, si lo prefieres, descargarlo, imprimirlo y colgarlo en tu nevera.
              <br />
              ¡Vamos a por ello!
            </p>
            <p>Parece que se ha producido un error.</p>
          </>
        )}

        {loading &&
          lastError === null &&
          citaPendiente === false &&
          citaRealizada === false &&
          !dietPlan && (
            <p>
              Para poder obtener tu menú de dieta personalizado tendrás primero que{" "}
              <a onClick={() => handleOptionClick("Calendar")}>agendar tu videollamada</a>. Una vez
              la realices o decidas no agendarla, el especialista te enviará tu plan y podrás
              visualizarlo en tu navegador o, si lo prefieres, descargarlo, imprimirlo y colgarlo en
              tu nevera.
              <br /> ¡Vamos a por ello!
            </p>
          )}

        {loading &&
          lastError === null &&
          citaPendiente === true &&
          citaRealizada === false &&
          !dietPlan && (
            <p>
              Para poder obtener tu menú de dieta personalizado tendrás primero que finalizar tu
              videollamada. Una vez realizada, el especialista enviará tu plan y podrás visualizarlo
              en tu navegador o, si lo prefieres, descargarlo, imprimirlo y colgarlo en tu nevera.
              <br /> ¡Vamos a por ello!
            </p>
          )}

        {loading && lastError === null && citaRealizada === true && (
          <p>
            Estamos personalizando tu dieta, una vez lo tengas podras descargarlo desde aquí,
            visualizarlo en tu navegador o, si lo prefieres, descargarlo, imprimirlo y colgarlo en
            tu nevera.
            <br /> ¡Vamos a por ello!
          </p>
        )}

        {dietPlan && (
          <>
            <p>
              {isGeopolPlan
                ? "Descarga aquí tu guía nutricional. Podrás visualizarla en tu navegador o, si lo prefieres, descargarla."
                : "Descarga desde aquí tu menú de dieta personalizado. Podrás visualizarlo en tu navegador o, si lo prefieres, descargarlo, imprimirlo y colgarlo en tu nevera."}
              <br />
              ¡Vamos a por ello!
            </p>
            <div className="your_diet_download" onClick={() => onClick("diet")}>
              <img className="img_your_diet" src={Image} alt="img" />
              <span>{isGeopolPlan ? "DESCARGA TU GUÍA NUTRICIONAL" : "DESCARGA TU DIETA"}</span>
              <img className="icon_your_diet" id="downloadButton" src={Icon} alt="icon" />
            </div>
            {interactiveCalendar && !isGeopolPlan && (
              <div className="your_calendar_download" onClick={() => onClick("calendar")}>
                <img className="img_your_calendar" src={ImageGift} alt="img" />
                {typePlan === "die_bb" ? (
                  <span>GUÍA DE EMBARAZO</span>
                ) : (
                  <span>CALENDARIO INTERACTIVO</span>
                )}
                <span> </span>
                <img
                  className={
                    typePlan === "die_bb" ? "icon_your_calendar_pregnancy" : "icon_your_calendar"
                  }
                  src={Icon}
                  alt="icon"
                />
              </div>
            )}
            {!isGeopolPlan && (
              <div className="hint-diet">
                <img src={Info} alt="" />
                <p>
                  Recuerda que dispones del{" "}
                  <a onClick={() => handleOptionClick("Contact Specialist")}>chat</a> con tu
                  especialista para consultar cualquier duda sobre el plan que te pueda surgir.
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default YourDiet
